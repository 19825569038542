/* You can add global styles to this file, and also import other style files */
// regular style toast
@import 'ngx-toastr/toastr';
@import "node_modules/flag-icons/css/flag-icons.min.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// For full screen angular material dialogs
// See https://www.freakyjolly.com/angular-material-full-screen-dialog-modal/
.fullscreen-dialog {
    justify-content: center;
    max-width: 100% !important;
    max-height: 100% !important;
    position: absolute !important;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .mat-mdc-dialog-container {
        max-width: 1200px !important;
    }
}

.custom-chip-label {
    span.mat-mdc-chip-action-label {
        color: white !important;
    }
}

.loading .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px;
}

/* Custom style for positioning toast messages at the bottom-right corner */
.toast-bottom-right {
    bottom: 1rem;
    right: 1rem;
  }
