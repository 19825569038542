@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$amber-palette, 400);
$my-accent: mat.define-palette(mat.$gray-palette, 900);

$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent
        ),
        typography: mat.define-typography-config(),
        density: 0,
));

@include mat.all-component-themes($my-theme);

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  border-radius: 100px !important;
}
